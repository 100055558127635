<template>
  <div>
    <a-modal
      width="1000px"
      v-model="isShow"
      centered
      :mask-closable="false"
      @cancel="handleCancel"
      title="开发信息"
    >
      <template slot="footer">
        <a-button class="ant-btn-primary" @click="closeModal">
          关闭
        </a-button>
      </template>

      <a-spin :spinning="loading">
        <a-row type="flex" justify="end" v-if="isHasApiSettingPermission">
          <a-col :span="2" v-if="info.is_api_enabled">
            <a @click="showEditModal()">编辑</a>
          </a-col>
        </a-row>

        <a-row>
          <a-col :span="8">
            <a-descriptions
              :column="1"
              class="simiot-descriptions simiot-descriptions-max-6"
            >
              <a-descriptions-item label="API权限">
                <a-switch
                  :checked="info.is_api_enabled"
                  :loading="editingIsApiEnabled"
                  @change="changeIsApiEnabled"
                  v-if="isHasApiSettingPermission"
                />
                <span v-else>
                  {{ info.is_api_enabled_display }}
                </span>
              </a-descriptions-item>

              <a-descriptions-item
                label="API ID"
                v-if="info.is_api_enabled"
              >
                {{ info.api_id }}
                <a-tooltip>
                  <template slot="title">
                    点击复制
                  </template>
                  <a-icon
                    class="simiot-cursor-pointer"
                    type="copy"
                    theme="twoTone"
                    v-clipboard:copy="info.api_id"
                    v-clipboard:success="onCopy"
                    v-clipboard:error="onCopyError"
                  />
                </a-tooltip>
              </a-descriptions-item>

              <a-descriptions-item
                label="API SECRET"
                v-if="info.is_api_enabled"
              >
                {{ this.$lodash.truncate(info.api_secret, { length: 20, omission: '...'}) }}
                <a-tooltip>
                  <template slot="title">
                    点击复制
                  </template>
                  <a-icon
                    class="simiot-cursor-pointer"
                    type="copy"
                    theme="twoTone"
                    v-clipboard:copy="info.api_secret"
                    v-clipboard:success="onCopy"
                    v-clipboard:error="onCopyError"
                  />
                </a-tooltip>
              </a-descriptions-item>
            </a-descriptions>
          </a-col>

          <a-col :span="8" v-if="info.is_api_enabled">
            <a-descriptions
              :column="1"
              class="simiot-descriptions simiot-descriptions-max-8"
            >
              <a-descriptions-item label="调用API的IP地址1">
                {{ info.api_ip_1 }}
              </a-descriptions-item>

              <a-descriptions-item label="调用API的IP地址2">
                {{ info.api_ip_2 }}
              </a-descriptions-item>

              <a-descriptions-item label="调用API的IP地址3">
                {{ info.api_ip_3 }}
              </a-descriptions-item>
            </a-descriptions>
          </a-col>

          <a-col :span="8" v-if="info.is_api_enabled">
            <a-descriptions
              :column="1"
              class="simiot-descriptions simiot-descriptions-max-8"
            >
              <a-descriptions-item label="查询接口调用限制">
                {{ info.api_query_rate }}
              </a-descriptions-item>

              <a-descriptions-item label="操作接口调用限制">
                {{ info.api_operation_rate }}
              </a-descriptions-item>
            </a-descriptions>
          </a-col>
        </a-row>
      </a-spin>

      <div
        v-if="info.is_api_enabled"
        class="api-list-box"
      >
        <a-descriptions
          title="API 列表"
        />

        <agent-api-list :agent-id="id" />
      </div>

      <edit-api-info
        v-if="isShowEditModal"
        :visible.sync="isShowEditModal"
        :agent-id="id"
        @completed="fetchData"
      />
    </a-modal>
  </div>
</template>

<script>
import { findAgentApiInfo, updateAgentIsApiEnabled } from '@/api/agent'
import AgentApiList from '@/views/agents/info/ApiList'
import { hasPermission } from '@/utils/permission'

export default {
  name: 'ShowApiDetail',
  components: {
    AgentApiList,
    EditApiInfo: () => import('@/views/agents/info/EditApiInfo')
  },
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    id: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      info: {},
      loading: true,
      isShowEditModal: false,
      editingIsApiEnabled: false,
      form: this.$form.createForm(this, { name: 'edit_agency_api_info' })
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    },

    isHasApiSettingPermission() {
      return hasPermission('agents/api_setting')
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    showEditModal() {
      this.isShowEditModal = true
    },

    fetchData() {
      this.loading = true
      findAgentApiInfo(this.id).then((res) => {
        if (res.code === 0) {
          this.info = res.data
        }
        this.loading = false
      })
    },

    // 开关API权限
    changeIsApiEnabled(enabled) {
      const vm = this
      this.$confirm({
        title: enabled ? '确定开启权限吗?' : '确定关闭权限吗？关闭后，客户将无法使用API',
        content: '',
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          vm.editingIsApiEnabled = true
          updateAgentIsApiEnabled(vm.id, { is_api_enabled: enabled })
            .then((res) => {
              vm.editingIsApiEnabled = false
              if (res.code === 0) {
                vm.info.is_api_enabled = enabled
              }
            })
            .catch(() => {
              vm.editingIsApiEnabled = false
            })
        }
      })
    },

    onCopy: function(e) {
      this.$message.success('复制成功！')
    },

    onCopyError: function(e) {
      this.$message.error('复制失败！')
    },

    handleCancel() {
      this.$emit('completed')
    },

    closeModal() {
      this.isShow = false
      this.$emit('completed')
    }
  }
}
</script>
